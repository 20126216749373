<template>
  <v-card id="stepfooter-contact-page" class="stepfooter-page ma-0 pa-3 d-flex align-center justify-space-between" tile color="primary">
    <v-row class="ma-0 pa-0">
      <v-col cols="4" class="pa-0 d-flex align-center">
        <v-btn v-if="currentPage !== 1" color="secondary" class="font-weight-bold"
          elevation="2" @click="$emit('back')" rounded>
          <v-icon left dark>mdi-chevron-left</v-icon> Back
        </v-btn>
      </v-col>
      <v-col cols="4" class="pa-0 d-flex justify-center align-center">
        <div class="font-weight-medium mr-6 text-caption white--text text-capitalize cursor-pointer" @click="resetForm">
          <div class="d-flex flex-column justify-center align-center">
            <v-icon left dark color="iconbg" class="mb-1 mr-0">mdi-refresh</v-icon>
            <div>Restart</div>
          </div>
        </div>
      </v-col>
      <v-col cols="4" class="pa-0 d-flex align-center justify-end">
        <v-btn
          color="secondary"
          class="font-weight-bold"
          elevation="2"
          type="submit"
          :loading="contactLoading"
          rounded
          :disabled="contactLoading && donorSearchLists.length === 0"
          @click="onNextStep"
        >
          {{ currentPage === 2 ? 'Save Contact' : 'Next' }}
          <v-icon right dark>mdi-chevron-right</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-dialog v-model="showAlert" max-width="350">
      <v-card>
        <v-card-title class="text-h6">Notice</v-card-title>
        <div class="text-center">
          <div>No Contact Selected</div>
          <div>This will create a new care contact.</div>
        </div>
        <v-card-actions>
          <v-row justify="center" class="w-100">
            <v-col cols="auto">
              <v-btn color="primary" @click="closeDialog">Proceed</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "stepFooter",
  props: ["currentPage", "resetValidation", "showAlertDialog"],
  emits: ["goHome", "next"],
  data() {
    return {
      showAlert: false,
    };
  },
  computed: {
    ...mapGetters(["model", "contactLoading", "selectedDonor", "donorSearchLists"])
  },
  watch: {
    showAlertDialog(newVal) {
      this.showAlert = newVal;
    }
  },
  methods: {
    resetForm() {
      if (this.resetValidation) {
        this.resetValidation();
      }
      this.$store.dispatch('refreshData');
      this.$store.commit("setModel", {});
      this.$store.commit("setSelectedDonor", {});
      this.$store.commit("setSearchLists", []);
      this.$emit('goHome');
    },
    onNextStep() {
      if (this.showAlertDialog || (!this.selectedDonor || Object.keys(this.selectedDonor).length === 0)) {
        this.showAlert = false;
      } else {
        this.$emit('next');
      }
    },
    closeDialog() {
      this.showAlert = false;
    }
  }
};
</script>
